<template>
  <div>
    <!-- <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <div class="card-title">
              <h3 class="card-label">
                Bank Kirim malumotlari
              </h3>
            </div>
          </div>

          <div class="card-body">
            <div>
              <v-tabs v-model="tab" class="wizard-step ">
                <v-tab v-for="item in items" :key="item.tab">
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <ProductServices />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-3"
          id="kt_wizard_v3"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-nav border-bottom mb-1 mb-lg-5">
            <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label">
                  <h3 class="wizard-title">Tovar va Hizmatlar</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center px-8 px-lg-10">
            <div class="col-xl-12 col-xxl-7">
              <form class="form" id="kt_form">
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <!-- <h4 class="mb-5 font-weight-bold text-dark">
                    Setup Your Current Location
                  </h4> -->
                  <ProductServices />
                  <!-- <div class="form-group">
                    <label>Address Line 1</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      name="address1"
                      placeholder="Address Line 1"
                      value="Address Line 1"
                    />
                    <span class="form-text text-muted"
                      >Please enter your Address.</span
                    >
                  </div> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTWizard from '@/assets/js/components/wizard'
import ProductServices from './DetailComponents/ProductServices'
// import Factura from "./components/factura";
export default {
  name: 'ContractDetails',
  components: {
    ProductServices
  },
  data() {
    return {
      tab: 1,
      items: [
        {
          tab: 'Tovar va Hizmatlar'
        }
      ]
    }
  },
  beforeCreate() {},
  computed: {},
  methods: {
    initWizard() {
      this.$nextTick(() => {
        // Initialize form wizard
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        })

        // Validation before going to next page
        wizard.on('beforeNext', function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        })
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Tovar Hizmatlari kirmi' },
      { title: 'Bank Kirim Hujjatlari' }
    ])
    this.initWizard()
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>
<style scoped>
.wizard-step {
  padding: 0;
}
.card-body {
  padding: 0 !important;
}
</style>
